import SceneManager, { ThreeAppState } from './SceneManager';
import Stats from 'stats.js';

export default class ThreeApp {
  private sceneManager: SceneManager;
  constructor(containerElement: HTMLElement) {
    const canvas = document.createElement(`canvas`);
    containerElement.appendChild(canvas);

    const sceneManager = new SceneManager(canvas);
    this.sceneManager = sceneManager;

    // resize canvas and sceneManager when window resizes
    window.addEventListener('resize', resizeCanvas);

    // and once initially.
    resizeCanvas();

    const isDebug = window.location.search.includes('debug');
    let stats: Stats | null = null;
    if (isDebug) {
      stats = new Stats();
      stats.showPanel(2); // 0: fps, 1: ms, 2: mb, 3+: custom
      containerElement.appendChild(stats.dom);
    }

    function resizeCanvas() {
      canvas.style.width = `100%`;
      canvas.style.height = `100%`;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      sceneManager.onWindowResize();
    }

    render();

    function render() {
      stats && stats.begin();
      // monitored code goes here
      sceneManager.update();
      stats && stats.end();
      requestAnimationFrame(render);
    }
  }

  update(state: ThreeAppState, prevState: ThreeAppState) {
    this.sceneManager.updateState(state, prevState);
  }
}
