import SceneSubject from './SceneSubject';
import { ISceneHelper } from './ISceneHelper';
import * as THREE from 'three';
import { BasicSceneSubject } from './BasicSceneSubject';
// import { BulbLight } from './BulbLight';

export class Lights extends SceneSubject {
  // bulbLight: BasicSceneSubject;
  hemiLight: BasicSceneSubject;
  constructor(sceneHelper: ISceneHelper) {
    super(sceneHelper, 'Lights');
    // this.bulbLight = new BulbLight(sceneHelper);

    const hemiLight = new THREE.HemisphereLight(0xddeeff, 0x0f0e0d, 0.02);
    hemiLight.intensity = 3; // 30 looks good with renderer.toneMapping

    // with renderer.toneMapping enabled use
    // hemiLight.intensity = 30;
    // bulbLight.power = 5000; // see https://github.com/mrdoob/three.js/blob/master/examples/webgl_lights_physical.html
    // renderer.toneMapping = THREE.ReinhardToneMapping;
    // renderer.toneMappingExposure = Math.pow( 0.50, 5.0 ); // to allow for very bright scenes.
    this.hemiLight = new BasicSceneSubject(hemiLight, sceneHelper, 'hemiLight');
  }
}
