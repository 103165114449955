import SceneSubject from './SceneSubject';
import * as THREE from 'three';
import { ISceneHelper } from './ISceneHelper';
import { PictureWall } from './PictureWall';
import { DoubleSide } from 'three';
import { VideoTrack, LocalVideoTrack, Room } from 'twilio-video';
import { ThreeAppState } from './SceneManager';

const left = -25; // x pos for left wall.
const right = 25; // x pos for right wall.
const back = -25; // z pos for back wall.
const front = +25; // z pos for front wall.
const yPos = 10; // y post for all walls (they are all 20 tall)
export const deltaSpace = 0.2;

export class Wall extends SceneSubject {
  room: Room | null = null;
  walls: PictureWall[] = [];
  constructor(sceneHelper: ISceneHelper, parentEl: HTMLElement) {
    super(sceneHelper, 'walls');

    const loader = new THREE.TextureLoader();
    // walls
    const diffuseTex = loader.load('/assets/brick_diffuse.jpg', () => {
      console.log('loaded textures/brick_diffuse.jpg');
    });
    const bumpTex = loader.load('assets/brick_bump.jpg', () => {
      console.log('loaded textures/brick_bump.jpg');
    });

    const wallHardWoodMaterial = new THREE.MeshPhysicalMaterial({
      map: diffuseTex,
      bumpMap: bumpTex,
      bumpScale: 0.3,
      side: DoubleSide,
    });
    const wallSimplerMaterial = new THREE.MeshBasicMaterial({ color: 0xcccccc });

    const isHardwood = false;
    const wallMat = isHardwood ? wallHardWoodMaterial : wallSimplerMaterial;

    const backWallPosition = new THREE.Vector3(0, yPos, back);
    const rightWallPosition = new THREE.Vector3(right, yPos, 0);
    const leftWallPosition = new THREE.Vector3(left, yPos, 0);
    const frontWallPosition = new THREE.Vector3(0, yPos, front);
    this.walls = [
      new PictureWall('backWall', sceneHelper, parentEl, wallMat, backWallPosition, 0),
      new PictureWall('rightWall', sceneHelper, parentEl, wallMat, rightWallPosition, -Math.PI / 2),
      new PictureWall('leftWall', sceneHelper, parentEl, wallMat, leftWallPosition, Math.PI / 2),
      new PictureWall('frontWall', sceneHelper, parentEl, wallMat, frontWallPosition, Math.PI),
    ];

    sceneHelper.addObject(this);
  }

  /**
   * looks for all local and remote participant tracks, and adds any new tracks to wall, and removes any old tracks.
   * @param state
   * @param prevState
   */
  updateState(state: ThreeAppState, prevState: ThreeAppState) {
    console.log('makarand: updating state');

    const updateWallTracks = () => {
      const videoTracks: VideoTrack[] = [];
      const localVideoTrack =
        state.localTracks && (state.localTracks.find(track => track.kind === 'video') as LocalVideoTrack);
      if (localVideoTrack) {
        videoTracks.push(localVideoTrack);
      }

      if (this.room) {
        [...this.room.participants.values()].forEach(participant => {
          function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
            return value !== null && value !== undefined;
          }
          [...participant.videoTracks.values()]
            .map(pub => pub.track)
            .filter(notEmpty)
            .forEach(track => videoTracks.push(track));
        });
      }
      this.renderTracks(videoTracks);
    };
    if (state.roomState === 'connected' && !this.room) {
      this.room = state.room;
      [...this.room.participants.values()].forEach(() => updateWallTracks());
      this.room.on('participantConnected', _participant => updateWallTracks());
      this.room.on('participantDisconnected', _participant => updateWallTracks());
      this.room.on('trackSubscribed', _participant => updateWallTracks());
      this.room.on('trackUnsubscribed', _participant => updateWallTracks());
    } else if (state.roomState === 'disconnected' && this.room) {
      // clean up room, and all its children.
      this.room = null;
    }
    updateWallTracks();
  }

  /**
   * adds new tracks to the wall, removes old tracks.
   */
  private renderTracks(videoTracks: VideoTrack[]) {
    // remove any stale tracks
    this.walls.forEach(wall => wall.updateTracks(videoTracks));
  }
}
