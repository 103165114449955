import {
  AudioTrack,
  VideoTrack,
  RemoteTrackPublication,
  LocalTrackPublication,
  DataTrack,
  RemoteParticipant,
  LocalParticipant,
} from 'twilio-video';
import { Robot } from './Robot';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { ISceneHelper } from './ISceneHelper';

export interface RemoteRobotParams {
  robotGLTF: GLTF;
  participant: RemoteParticipant | LocalParticipant;
  sceneHelper: ISceneHelper;
  position: THREE.Vector3;
  parentEl: HTMLElement;
}

export class RemoteRobot extends Robot {
  private params: RemoteRobotParams;
  constructor(params: RemoteRobotParams) {
    console.log('created RemoteRobot');
    super(params.robotGLTF, params.sceneHelper, params.position, params.parentEl);
    this.params = params;
    const participant = params.participant;
    participant.on('disconnected', () => {
      params.sceneHelper.removeObject(this);
    });

    const trackPublished = (publication: LocalTrackPublication | RemoteTrackPublication) => {
      if (publication.track) {
        console.log('already subscribed to track:', this.params.participant.identity);
        this.renderTrack(publication.track);
      } else {
        publication.on('subscribed', track => {
          console.log('subscribed to track:', this.params.participant.identity);
          this.renderTrack(track);
        });
      }
    };

    [...participant.tracks.values()].forEach(pub => trackPublished(pub as RemoteTrackPublication));
    participant.on('trackPublished', pub => trackPublished(pub));
  }

  renderTrack(track: AudioTrack | VideoTrack | DataTrack) {
    if (track.kind === 'data') {
      // we only handle remote data tracks here.
      track.on('message', message => {
        console.log('got message: ', message);
        try {
          const { position, rotation } = JSON.parse(message);
          this._object.position.set(position.x, position.y, position.z);
          this._object.rotation.set(rotation.x, rotation.y, rotation.z);
          this._object.updateMatrix();
        } catch (err) {
          console.log('Error parsing message: ', message, err);
        }
      });
    } else {
      super.renderTrack(track);
    }
  }

  disconnect(): void {
    this.params.sceneHelper.removeObject(this);
  }
}
