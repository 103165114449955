import * as THREE from 'three';
import { ISceneHelper } from './ISceneHelper';
import { BasicSceneSubject } from './BasicSceneSubject';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { AudioTrack, VideoTrack } from 'twilio-video';
import { VideoPlane } from './VideoPlane';

export function createVideoElement(): HTMLVideoElement {
  const videoEl = document.createElement('video');
  videoEl.style.display = 'none';
  videoEl.autoplay = true;
  return videoEl;
}

function createAudioElement(): HTMLAudioElement {
  const audioEl = document.createElement('audio');
  audioEl.style.display = 'none';
  audioEl.autoplay = true;
  return audioEl;
}

export class Robot extends BasicSceneSubject {
  parentEl: HTMLElement;
  videoEl: any;
  audioEl: any;
  group: THREE.Group;
  constructor(gltf: GLTF, sceneHelper: ISceneHelper, position: THREE.Vector3, parentEl: HTMLElement) {
    const model = gltf.scene.clone();
    // scene.position.set(position.x, position.y, position.z);
    model.receiveShadow = true;
    model.castShadow = true;
    const group = new THREE.Group();
    group.position.copy(position);
    group.add(model);
    super(group, sceneHelper, 'robot');

    this.group = group;
    this.parentEl = parentEl;
    this.videoEl = createVideoElement();
    this.audioEl = createAudioElement();
    this.parentEl.appendChild(this.videoEl);
  }

  setVideo(track: VideoTrack) {
    const videoDimensions = { width: 1.6, height: 1 };
    const facePlane = new VideoPlane(this.sceneHelper, new THREE.Vector3(0, 1.7, 0.2), this.parentEl, videoDimensions);
    // plane.rotateY(Math.PI);
    this.group.add(facePlane.getObject3D()!);
    facePlane.renderTrack(track);

    const backPlane = new VideoPlane(
      this.sceneHelper,
      new THREE.Vector3(0, 1.7, -0.2),
      this.parentEl,
      videoDimensions,
      Math.PI
    );
    // plane.rotateY(Math.PI);
    this.group.add(backPlane.getObject3D()!);
    backPlane.renderTrack(track);
  }

  renderTrack(track: AudioTrack | VideoTrack) {
    console.log('track.isStarted: ', track.kind, track.isStarted);
    if (track.kind === 'video') {
      // track.attach(this.videoEl);
      // webgl does not render video track if an audio track was attached to it.
      // WebGL: INVALID_VALUE: tex(Sub)Image2D: video visible size is empty
      this.setVideo(track);
    } else {
      track.attach(this.audioEl);
    }
  }
}
