import { VideoTrack } from 'twilio-video';
import { ISceneHelper } from './ISceneHelper';
import { createVideoElement } from './VideoCube';
import * as THREE from 'three';
import { BasicSceneSubject } from './BasicSceneSubject';

export class VideoPlane extends BasicSceneSubject {
  videoEl: HTMLVideoElement;

  constructor(
    sceneHelper: ISceneHelper,
    position: THREE.Vector3,
    parentEl: HTMLElement,
    { width, height }: { width: number; height: number },
    yRotation: number = 0
  ) {
    var geometry = new THREE.PlaneGeometry(width, height);
    var material = new THREE.MeshBasicMaterial({ color: 0xffff00, side: THREE.DoubleSide });
    var plane = new THREE.Mesh(geometry, material);
    plane.position.copy(position);
    plane.rotateY(yRotation);
    super(plane, sceneHelper, 'videoplane');
    this.videoEl = createVideoElement();
    parentEl.appendChild(this.videoEl);
  }

  // updateState(state: ThreeAppState, prevState: ThreeAppState) {
  //   const videoTrack = state.localTracks && state.localTracks.find(track => track.kind === 'video');
  //   console.log('Plane: videoTracks:', videoTrack ? 'yes' : 'no');
  //   if (videoTrack) {
  //     this.renderTrack(videoTrack as VideoTrack);
  //   }
  // }

  renderTrack(track: VideoTrack) {
    track.attach(this.videoEl);
    const texture = new THREE.VideoTexture(this.videoEl);
    const material = new THREE.MeshBasicMaterial({ map: texture });
    const o = this._object as THREE.Mesh;
    o.material = material;
  }

  disconnect(): void {
    this.videoEl.remove();
    this.sceneHelper.removeObject(this);
  }

  isDraggable() {
    return true;
  }
}
