import { Cube } from './Cube';
import * as THREE from 'three';

import { AudioTrack, VideoTrack } from 'twilio-video';
import { ISceneHelper } from './ISceneHelper';

export function createVideoElement(): HTMLVideoElement {
  const videoEl = document.createElement('video');
  videoEl.style.display = 'none';
  videoEl.autoplay = true;
  return videoEl;
}

function createAudioElement(): HTMLAudioElement {
  const audioEl = document.createElement('audio');
  audioEl.style.display = 'none';
  audioEl.autoplay = true;
  return audioEl;
}
export class VideoCube extends Cube {
  videoEl: HTMLVideoElement;
  audioEl: HTMLAudioElement;
  constructor(sceneHelper: ISceneHelper, position: THREE.Vector3, parentEl: HTMLElement) {
    super(sceneHelper, position);
    this.videoEl = createVideoElement();
    this.audioEl = createAudioElement();
    parentEl.appendChild(this.videoEl);
  }

  setVideo(video: HTMLVideoElement) {
    const texture = new THREE.VideoTexture(video);
    const material = new THREE.MeshBasicMaterial({ map: texture });
    const o = this._object as THREE.Mesh;
    o.material = material;
  }

  renderTrack(track: AudioTrack | VideoTrack) {
    console.log('track.isStarted: ', track.kind, track.isStarted);
    if (track.kind === 'video') {
      track.attach(this.videoEl);
      // webgl does not render video track if an audio track was attached to it.
      // WebGL: INVALID_VALUE: tex(Sub)Image2D: video visible size is empty
      this.setVideo(this.videoEl);
    } else {
      track.attach(this.audioEl);
    }
  }
}
