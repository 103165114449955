import * as THREE from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Room, LocalVideoTrack, LocalAudioTrack, RemoteParticipant } from 'twilio-video';
import { HardwoodFloor } from './HardwoodFloor';
import SceneSubject from './SceneSubject';
import { Wall } from './Wall';
import { RoomScene } from './RoomScene';
import { ISceneHelper } from './ISceneHelper';
import { Lights } from './Lights';
import { Label } from './Label';
import ReferenceCube from './ReferenceCube';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { LocalRobot } from './LocalRobot';
import { RemoteRobot } from './RemoteRobot';

export interface ThreeAppState {
  room: Room;
  roomState: string;
  localTracks: (LocalVideoTrack | LocalAudioTrack)[];
}

export default class SceneManager implements ISceneHelper {
  canvas: HTMLCanvasElement;
  scene: THREE.Scene;
  renderer: THREE.WebGLRenderer;
  player = { height: 1.8, speed: 0.2, turnSpeed: Math.PI * 0.02 };
  screenDimensions: { width: number; height: number };
  camera: THREE.PerspectiveCamera;
  clock = new THREE.Clock();
  sceneSubjects: SceneSubject[] = [];
  orbitControls: OrbitControls;
  dragControls: DragControls;
  room: Room | null = null;

  needsDragUpdate: boolean = false;
  robotGLTF: GLTF | null;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    const { width, height } = this.canvas;

    this.screenDimensions = { width, height };
    this.scene = this._buildScene();
    this.renderer = this._buildRenderer(this.canvas, this.screenDimensions);
    this._createSceneSubjects(this);
    const { camera, orbitControls } = this._createCameraPlayerAndControls();
    // const foo = this._loadAssets();
    this.camera = camera;
    this.orbitControls = orbitControls;
    this.dragControls = this._buildDragControls();
    this.robotGLTF = null;
    this._loadAssets();
  }

  _loadAssets() {
    const loader = new GLTFLoader();
    // load a resource
    loader.load(
      // resource URL
      '/assets/robot/robot.gltf',

      // called when resource is loaded
      gltf => {
        console.log('gltf:', gltf);
        gltf.scene.castShadow = true;
        gltf.scene.receiveShadow = true;
        this._onAssetsLoaded(gltf);
      },
      xhr => console.log((xhr.loaded / xhr.total) * 100 + '% loaded'),
      error => console.log('An error happened: ', error)
    );
  }

  private _onAssetsLoaded(gltf: GLTF) {
    const localPlayer = new LocalRobot(
      gltf,
      this,
      new THREE.Vector3(0, 0, 0),
      this.canvas,
      this.camera,
      this.orbitControls
    );

    // const localPlayer2 = new LocalRobot(gltf, this, new THREE.Vector3(2, 2, 2), this.canvas, this.camera, this.orbitControls);
    const roomScene = new RoomScene(this, this.canvas);
    this.robotGLTF = gltf;
    console.log({ localPlayer, roomScene });
  }

  _buildOrbitControls(camera: THREE.PerspectiveCamera, canvas: HTMLCanvasElement) {
    // also update the orbit and drag controls.
    const orbitControls = new OrbitControls(camera, canvas);
    orbitControls.enableKeys = false; // disable keyboard navigation.

    // orbitControls.autoRotate = true; // must call update
    orbitControls.enableRotate = true;
    // orbitControls.addEventListener('change', render); // call this only in static scenes (i.e., if there is no animation loop)

    // orbitControls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    orbitControls.dampingFactor = 0.05;

    orbitControls.screenSpacePanning = false;

    // this lets you move camera
    orbitControls.enablePan = true; // // Enable or disable camera panning. Default is true.

    // zoom influences pan speed.
    orbitControls.enableZoom = true;

    orbitControls.minDistance = 0;
    orbitControls.maxDistance = 500;

    orbitControls.maxPolarAngle = Math.PI / 2;
    return orbitControls;
  }

  _createCameraPlayerAndControls() {
    const { width, height } = this.screenDimensions;

    // camera
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    // camera.position.set(-2, 3, -3);
    // camera.lookAt(new THREE.Vector3(0, this.player.height, 0));

    const orbitControls = this._buildOrbitControls(camera, this.canvas);

    return { camera, orbitControls };
  }

  createRemoteParticipant(participant: RemoteParticipant) {
    console.log('adding remote robot: ', participant);
    if (this.robotGLTF) {
      const position = new THREE.Vector3(1, 0.75, 0);
      position.x = Math.random() * 6 - 3;
      position.z = Math.random() * 6 - 3;
      const remoteRobot = new RemoteRobot({
        robotGLTF: this.robotGLTF,
        participant,
        sceneHelper: this,
        position,
        parentEl: this.canvas,
      });
      console.log({ remoteRobot });
    }
  }

  addObject(obj: SceneSubject) {
    this.sceneSubjects.push(obj);
    const obj3d = obj.getObject3D();
    if (obj3d) {
      this.scene.add(obj3d);
    }

    if (obj.isDraggable()) {
      this.needsDragUpdate = true;
    }
  }

  removeObject(obj: SceneSubject) {
    const index = this.sceneSubjects.indexOf(obj);
    if (index >= 0) {
      this.sceneSubjects.splice(index, 1);
    }

    const obj3d = obj.getObject3D();
    if (obj3d) {
      this.scene.remove(obj3d);
    }
  }

  updateState(state: ThreeAppState, prevState: ThreeAppState) {
    this.sceneSubjects.forEach(subject => subject.updateState(state, prevState));
  }

  // is called by ThreeApp
  update() {
    if (this.needsDragUpdate) {
      this._updateDragControls();
    }
    const elapsedTime = this.clock.getElapsedTime();
    this.sceneSubjects.forEach(subject => subject.update(elapsedTime));

    // if (this.orbitControls) {
    //   this.orbitControls.update();
    // }

    this.renderer.render(this.scene, this.camera);
  }

  // is called by ThreeApp
  onWindowResize() {
    const { width, height } = this.canvas;
    this.screenDimensions = { width, height };
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  _buildScene(): THREE.Scene {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color('#000');
    return scene;
  }

  _buildRenderer(canvas: HTMLCanvasElement, { width, height }: { width: number; height: number }): THREE.WebGLRenderer {
    const renderer = new THREE.WebGLRenderer({ canvas, antialias: true, alpha: true });
    renderer.physicallyCorrectLights = true;
    renderer.outputEncoding = THREE.sRGBEncoding;
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    // Enable Shadows in the Renderer
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.BasicShadowMap;

    return renderer;
  }

  _createSceneSubjects(sceneHelper: ISceneHelper): void {
    // const isDebug = window.location.search.includes('debug');
    // if (isDebug) {
    // reference cube at the center of screen
    ReferenceCube.makeReferenceCube(sceneHelper, new THREE.Vector3(0, 0.75, 0));

    // label
    new Label(sceneHelper, new THREE.Vector3(5, 5, 5), '5, 5, 5');
    // }

    // const floor = new Floor(scene);
    new HardwoodFloor(sceneHelper);
    new Wall(sceneHelper, this.canvas);
    new Lights(sceneHelper);

    // const axesHelper = new THREE.AxesHelper( 5 );
    // new BasicSceneSubject(axesHelper, this, "foo");
    // scene.add( axesHelper );
  }

  _updateDragControls() {
    if (this.dragControls) {
      this.dragControls.dispose();
    }
    this.dragControls = this._buildDragControls();
    this.needsDragUpdate = false;
  }

  _buildDragControls() {
    function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
      return value !== null && value !== undefined;
    }
    const draggableSubjects = this.sceneSubjects.filter(subject => subject.isDraggable());
    const draggableMap = new Map(draggableSubjects.map(subject => [subject.getObject3D(), subject]));
    const draggableObjects = [...draggableMap.keys()].filter(notEmpty);
    const dragControls = new DragControls(draggableObjects, this.camera, this.canvas);

    function getSubject(event: THREE.Event) {
      const obj = event.object;
      const subject = draggableMap.get(obj);
      return subject!;
    }

    dragControls.addEventListener('dragstart', (event: THREE.Event) => {
      if (this.orbitControls) {
        this.orbitControls.enabled = false;
      }

      getSubject(event).onDragStart(event);
    });
    dragControls.addEventListener('drag', event => {
      getSubject(event).onDrag(event);
    });
    dragControls.addEventListener('dragend', event => {
      getSubject(event).onDragEnd(event);
      if (this.orbitControls) {
        this.orbitControls.enabled = true;
      }
    });
    return dragControls;
  }
}
