import * as THREE from 'three';
import { ISceneHelper } from './ISceneHelper';
import { BasicSceneSubject } from './BasicSceneSubject';
import { VideoPlane } from './VideoPlane';
import { VideoTrack } from 'twilio-video';
import { Vector3 } from 'three';
const deltaSpace = 0.2;

export class PictureWall extends BasicSceneSubject {
  currentTracks: Map<VideoTrack, VideoPlane>;
  parentEl: HTMLElement;
  group: THREE.Group;
  trackIndex: number;

  constructor(
    name: string,
    sceneHelper: ISceneHelper,
    parentEl: HTMLElement,
    wallMaterial: THREE.Material,
    position: THREE.Vector3,
    yRotation: number
  ) {
    // wall size.
    const planeGeo = new THREE.PlaneBufferGeometry(50, 20);
    const wallMesh = new THREE.Mesh(planeGeo, wallMaterial);
    const wall = new BasicSceneSubject(wallMesh, sceneHelper, 'pictureWall');
    // remove wall, as it would be added as a group later by super.
    sceneHelper.removeObject(wall);

    const group = new THREE.Group();
    group.position.copy(position);
    group.add(wall.getObject3D()!);
    group.rotateY(yRotation);

    super(group, sceneHelper, name);
    this.currentTracks = new Map<VideoTrack, VideoPlane>();
    this.parentEl = parentEl;
    this.group = group;
    this.trackIndex = 0;
  }

  updateTracks(tracks: VideoTrack[]) {
    console.log('makarand: updateTracks:', tracks.length);
    const current = [...this.currentTracks.keys()];

    // remove old tracks
    current.forEach(track => {
      if (!tracks.includes(track)) {
        const videoPlane = this.currentTracks.get(track)!;
        this.group.remove(videoPlane.getObject3D()!);
        this.currentTracks.delete(track);
        videoPlane.disconnect();
      }
    });

    tracks.forEach(track => {
      if (!current.includes(track)) {
        this.addTrack(track);
      }
    });
  }

  removeTrack(track: VideoTrack): void {
    if (this.currentTracks.has(track)) {
      const videoPlane = this.currentTracks.get(track)!;
      this.group.remove(videoPlane.getObject3D()!);
      this.currentTracks.delete(track);
      videoPlane.disconnect();
    }
  }

  addTrack(track: VideoTrack): void {
    console.log('makarand: adding track');
    const COLS = 4;
    const row = Math.floor(this.trackIndex / COLS);
    const col = this.trackIndex % COLS;

    // wall spans -25 -> +25 on x, 0 - 20 on y.
    // video are 10x10, so lest put 1st on
    // (-24, 1) (-12, 1), (-)

    const videoDimensions = { width: 10, height: 10 };
    const planePos = new THREE.Vector3(col * 12 - 18, row * 10 - 4.5, deltaSpace);
    const plane = new VideoPlane(this.sceneHelper, planePos, this.parentEl, videoDimensions);
    this.group.add(plane.getObject3D()!);
    plane.renderTrack(track);
    this.currentTracks.set(track, plane);
    this.trackIndex++;
  }
}
