import { ThreeAppState } from './SceneManager';
import SceneSubject from './SceneSubject';
import { Room, RemoteParticipant } from 'twilio-video';
import * as THREE from 'three';
import { ParticipantCube } from './ParticipantCube';
import { ISceneHelper } from './ISceneHelper';

export class RoomScene extends SceneSubject {
  room: Room | null = null;
  participantCubes: ParticipantCube[] = [];
  canvas: HTMLElement;

  constructor(sceneHelper: ISceneHelper, canvas: HTMLElement) {
    super(sceneHelper, 'room');
    this.canvas = canvas;
    this.sceneHelper.addObject(this);
  }

  getObject3D(): THREE.Object3D | null {
    return null;
  }

  updateState(state: ThreeAppState, prevState: ThreeAppState) {
    console.log('state.roomState: ', state.roomState);
    if (state.roomState === 'connected' && !this.room) {
      this.room = state.room;
      const room = state.room;
      const createParticipantCube = (participant: RemoteParticipant) => {
        this.sceneHelper.createRemoteParticipant(participant);
        // console.log('adding participant: ', participant);
        // const position = new THREE.Vector3(1, 0.75, 0);
        // position.x = Math.random() * 6 - 3;
        // position.z = Math.random() * 6 - 3;
        // const pParticipantCube = new ParticipantCube({
        //   participant,
        //   sceneHelper: this.sceneHelper,
        //   localTracks: state.localTracks || [],
        //   position,
        //   parentEl: this.canvas,
        // });
        // this.participantCubes.push(pParticipantCube);
      };
      [...room.participants.values()].forEach(participant => createParticipantCube(participant));
      room.on('participantConnected', participant => createParticipantCube(participant));
      // TODO: handle participantDiscConnected
    } else if (state.roomState === 'disconnected' && this.room) {
      // clean up room, and all its children.
      this.participantCubes.forEach(cube => cube.disconnect());
      this.room = null;
    }
  }
}
