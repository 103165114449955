import * as THREE from 'three';
import { ISceneHelper } from './ISceneHelper';
import { BasicSceneSubject } from './BasicSceneSubject';
import { TextureLoader } from 'three';

function loadTexture(assetPath: string): Promise<THREE.Texture> {
  return new Promise((resolve, reject) => {
    const textureLoader = new TextureLoader();
    textureLoader.load(
      assetPath,
      texture => resolve(texture),
      progressEvent => console.log(`Progress Loading ${assetPath}`, progressEvent),
      errorEvent => reject(new Error(`Error Loading Texture ${assetPath} : ${errorEvent.message}`))
    );
  })
}

export default class ReferenceCube extends BasicSceneSubject {
  // create an array with six textures for a cool cube
  static async makeReferenceCube(sceneHelper: ISceneHelper, position: THREE.Vector3) {
    const textureArray = await Promise.all([
      '/assets/images/xpos.png',
      '/assets/images/xneg.png',
      '/assets/images/ypos.png',
      '/assets/images/yneg.png',
      '/assets/images/zpos.png',
      '/assets/images/zneg.png'
    ].map((asset) => {
      const texturePromise = loadTexture(asset);
      return texturePromise;
    }));

    const materialArray = textureArray.map(texture => new THREE.MeshBasicMaterial({ map: texture }));
    return new ReferenceCube(sceneHelper, position, materialArray);
  }
  constructor(sceneHelper: ISceneHelper, position: THREE.Vector3, materialArray: THREE.Material[] =  []) {
    const geometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);
    const movingCube = new THREE.Mesh(geometry, materialArray);
    movingCube.position.set(position.x, position.y, position.z);
    super(movingCube, sceneHelper, 'referenceCube');
  }

  isDraggable() {
    return true;
  }
}
