import React, { Component, createRef } from 'react';
import { styled } from '@material-ui/core/styles';
import ThreeApp from './ThreeApp';
import { ThreeAppState } from './SceneManager';

const CanvasContainer = styled('div')({
  margin: '5px',
  border: 'solid red 1px',
  width: '100%',
  height: '100%',
});

export default class ThreeContainer extends Component<ThreeAppState> {
  // static contextType = VideoContext;
  private mount = createRef<HTMLDivElement>();
  private threeApp: ThreeApp | null = null;

  constructor(props: ThreeAppState) {
    super(props);
    console.log('ThreeContainer.constructor:', props);
  }

  componentDidMount() {
    console.log('ThreeContainer.componentDidMount:', this.props);
    let value = this.context;
    console.log(value);
    if (!this.threeApp) {
      this.threeApp = new ThreeApp(this.mount.current!);
    }
  }

  componentDidUpdate(prevProps: ThreeAppState) {
    this.threeApp!.update(this.props, prevProps);
  }

  render() {
    return <CanvasContainer ref={this.mount} />;
  }
}
