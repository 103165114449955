import * as THREE from 'three';
import { ISceneHelper } from './ISceneHelper';
import { BasicSceneSubject } from './BasicSceneSubject';

export class HardwoodFloor extends BasicSceneSubject {
  constructor(sceneHelper: ISceneHelper) {
    const floorMat = new THREE.MeshStandardMaterial({
      roughness: 0.8,
      color: 0xffffff,
      metalness: 0.2,
      bumpScale: 0.0005,
    });
    var textureLoader = new THREE.TextureLoader();
    textureLoader.load('/assets/hardwood2_diffuse.jpg', function(map) {
      console.log('loaded: hardwood2_diffuse.jpg');
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.anisotropy = 4;
      map.repeat.set(10, 24);
      map.encoding = THREE.sRGBEncoding;
      floorMat.map = map;
      floorMat.needsUpdate = true;
    });
    textureLoader.load('/assets/hardwood2_bump.jpg', function(map) {
      console.log('loaded: hardwood2_bump.jpg');
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.anisotropy = 4;
      map.repeat.set(10, 24);
      floorMat.bumpMap = map;
      floorMat.needsUpdate = true;
    });
    textureLoader.load('/assets/hardwood2_roughness.jpg', function(map) {
      console.log('loaded: hardwood2_roughness.jpg');
      map.wrapS = THREE.RepeatWrapping;
      map.wrapT = THREE.RepeatWrapping;
      map.anisotropy = 4;
      map.repeat.set(10, 24);
      floorMat.roughnessMap = map;
      floorMat.needsUpdate = true;
    });
    const floorGeometry = new THREE.PlaneBufferGeometry(50, 50);
    const floorMesh = new THREE.Mesh(floorGeometry, floorMat);
    floorMesh.receiveShadow = true;
    floorMesh.rotation.x = -Math.PI / 2.0;
    super(floorMesh, sceneHelper, 'hardwoodfloor');
  }
}
