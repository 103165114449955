import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { LocalDataTrack, Room, VideoTrack } from 'twilio-video';
import { ISceneHelper } from './ISceneHelper';
import { Robot } from './Robot';
import { ThreeAppState } from './SceneManager';

const LEFT = 37;
const RIGHT = 39;
const UP = 38;
const DOWN = 40;
const W = 87;
const S = 83;
const A = 65;
const D = 68;

export class LocalRobot extends Robot {
  camera: THREE.PerspectiveCamera;
  controls: OrbitControls;
  room: Room | null;
  constructor(
    gltf: GLTF,
    sceneHelper: ISceneHelper,
    position: THREE.Vector3,
    parentEl: HTMLElement,
    camera: THREE.PerspectiveCamera,
    controls: OrbitControls
  ) {
    super(gltf, sceneHelper, position, parentEl);
    document.addEventListener('keydown', event => this.moveRobot(event), false);
    this.camera = camera;
    this.controls = controls;
    this.room = null;
    this.updateCamera();
    this.controls.update();
  }

  getLocalDataTrack(): LocalDataTrack | undefined {
    if (this.room && this.room.localParticipant) {
      const trackPublications = [...this.room.localParticipant.dataTracks.values()];
      const tracks = trackPublications.filter(pub => pub.track).map(pub => pub.track);
      if (tracks.length > 0) {
        return tracks[0] as LocalDataTrack;
      }
    }
  }

  sendUpdatedPosition() {
    const { x, y, z } = this._object.position;
    console.log('sending position:', { x, y, z });
    const { x: xr, y: yr, z: zr } = this._object.rotation;
    const dataTrack = this.getLocalDataTrack();
    if (dataTrack) {
      dataTrack.send(
        JSON.stringify({
          position: { x, y, z },
          rotation: { x: xr, y: yr, z: zr },
        })
      );
    }
    this._object.updateMatrix();
  }

  updateState(state: ThreeAppState, prevState: ThreeAppState) {
    const videoTrack = state.localTracks && state.localTracks.find(track => track.kind === 'video');
    console.log('LocalVideoCube videoTracks:', videoTrack ? 'yes' : 'no');
    if (videoTrack) {
      this.renderTrack(videoTrack as VideoTrack);
    }

    if (state.roomState === 'connected' && !this.room) {
      // connected
      this.room = state.room;
      this.sendUpdatedPosition();
    } else if (state.roomState === 'disconnected' && this.room) {
      // disconnected
      this.room = null;
    }
  }

  private updateCamera() {
    const v = new THREE.Vector3();
    v.copy(this._object.position);
    const cameraFromCube = new THREE.Vector3(0, 3, -4);
    const targetFromCube = new THREE.Vector3(0, 0, 4);
    cameraFromCube.applyQuaternion(this._object.quaternion);
    targetFromCube.applyQuaternion(this._object.quaternion);

    // update camera position
    this.camera.position.copy(v);
    this.camera.position.add(cameraFromCube);

    // update camera target position
    this.controls.target.copy(v);
    this.controls.target.add(targetFromCube);

    this.camera.updateMatrix();
    this._object.updateMatrix();
  }

  moveRobot(event: KeyboardEvent) {
    // var delta = clock.getDelta(); // seconds.
    // var moveDistance = 200 * delta; // 200 pixels per second
    // var rotateAngle = Math.PI / 2 * delta;   // pi/2 radians (90 degrees) per second

    const delta = 1;
    const rotDelta = Math.PI / 20;
    let zDelta = 0;
    let yRotation = 0;
    const keyCode = event.which;
    switch (keyCode) {
      case UP:
      case W:
        zDelta = delta;
        break;

      case DOWN:
      case S:
        zDelta = -delta;
        break;

      case LEFT:
      case A:
        yRotation = rotDelta;
        break;

      case RIGHT:
      case D:
        yRotation = -rotDelta;
        break;
    }

    if (zDelta || yRotation) {
      const mesh: THREE.Mesh = this._object as THREE.Mesh;
      if (zDelta) {
        const dir = new THREE.Vector3(0, 0, zDelta);
        dir.applyQuaternion(this._object.quaternion);
        mesh.position.add(dir);
      } else if (yRotation) {
        mesh.rotation.y += yRotation;
      }
      this.updateCamera();
      this.controls.update();
      this.sendUpdatedPosition();
    }
  }
}
