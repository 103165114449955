import * as THREE from 'three';
import SceneSubject from './SceneSubject';
import { ISceneHelper } from './ISceneHelper';

export class Label extends SceneSubject {
  _object: THREE.Mesh | null = null;
  textGeo: THREE.TextBufferGeometry | undefined;
  font: THREE.Font | undefined;
  constructor(sceneHelper: ISceneHelper, position: THREE.Vector3, label: string) {
    super(sceneHelper, 'text');
    var loader = new THREE.FontLoader();
    loader.load('/assets/fonts/gentilis_regular.typeface.json', font => {
      this.font = font;
      this.textGeo = new THREE.TextBufferGeometry(label, {
        font: font,
        size: 0.4,
        height: 0.2,
        curveSegments: 1,
      });
      const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
      const textMesh = new THREE.Mesh(this.textGeo, textMaterial);
      textMesh.position.copy(position);
      this._object = textMesh;
      sceneHelper.addObject(this);
    });
  }

  // update(elapsedTime: number) {
  //   if (this._object) {
  //     const { x, y, z } = this._object.position;
  //     console.log('updating name for lable');
  //     this._object.geometry.name = `${x},${y},${z}`;
  //   }
  // }

  onDrag(event: THREE.Event): void {
    if (event.object) {
      const { x, y, z } = event.object.position;
      const newLabel = `${x.toFixed(2)},${y.toFixed(2)},${z.toFixed(2)}`;
      const newGeo = new THREE.TextBufferGeometry(newLabel, {
        font: this.font!,
        size: 0.4,
        height: 0.2,
        curveSegments: 1,
      });
      this._object!.geometry = newGeo;
    }
  }

  onDragStart(event: THREE.Event): void {
    const { x, y, z } = event.object.position;
    console.log('onDragStart: ', x.toFixed(2), y.toFixed(2), z.toFixed(2));
  }

  isDraggable() {
    return !!this._object;
  }

  getObject3D() {
    return this._object;
  }
}
