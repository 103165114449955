import { Object3D } from 'three';
import { ThreeAppState } from './SceneManager';
import { ISceneHelper } from './ISceneHelper';

export default abstract class SceneSubject {
  static staticInstance: number = 1;
  sceneHelper: ISceneHelper;
  name: string;
  instanceId: number;
  constructor(scene: ISceneHelper, name: string) {
    this.instanceId = SceneSubject.staticInstance++;
    this.name = name;
    this.sceneHelper = scene;
  }

  update(elapsedTime: number) {
    // console.log(`Not updating ${this.name} @ ${elapsedTime}`);
  }

  updateState(_state: ThreeAppState, _prevState: ThreeAppState) {}

  getObject3D(): Object3D | null {
    return null;
  }
  isDraggable(): boolean {
    return false;
  }
  onDragStart(event: THREE.Event): void {}
  onDragEnd(event: THREE.Event): void {}
  onDrag(event: THREE.Event): void {}
}
