import { Object3D } from 'three';
import { ISceneHelper } from './ISceneHelper';
import SceneSubject from './SceneSubject';

export class BasicSceneSubject extends SceneSubject {
  _object: Object3D;
  constructor(object: Object3D, scene: ISceneHelper, name: string) {
    super(scene, name);
    this._object = object;
    this._object.name = name;
    this._object.matrixAutoUpdate = false;
    this._object.updateMatrix();
    scene.addObject(this);
  }

  getObject3D(): Object3D | null {
    return this._object;
  }
}
